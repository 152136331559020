import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class IconButtonWithProgress extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.style,
          position: "relative",

          width: 64,
          height: 64,
        }}
      >
        <Button
          disabled={this.props.disabled || this.props.busy}
          variant={this.props.variant}
          color={this.props.color}
          type={this.props.type}
          onClick={this.props.onClick}
          style={{
            position: "absolute",
            top: 4,
            left: 4,
            margin: 0,
            padding: 0,
          }}
        >
          {this.props.icon}
        </Button>
        {this.props.busy && (
          <CircularProgress
            size={64}
            style={{
              position: "absolute",
            }}
          />
        )}
      </div>
    );
  }
}
