import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";
import { TOKEN } from "../constants";
import { TOPICS_QUERY } from "../pages/knowledgeBase/index";
import { SUGGESTIONS_QUERY } from "./Dashboard/Suggestions";
import { slugging } from "../helpers";

export const Item = styled.div`
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.17;
  margin: 18px 12px;
  padding: 15px 18px;
`;

export const ItemTitle = styled.h3`
  color: #2a2135;
`;

export const ItemButton = styled.button`
  background-color: inherit;
  border: 0;
  color: #42c175;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
  text-align: left;
`;

const filterBatchedTodos = (batches = []) => {
  const availableTodos = [];
  const now = new Date();

  for (const { items = [] } of batches) {
    availableTodos.push(...items);
  }

  return availableTodos
    .filter(todo => todo.status === "ACTIVE")
    .filter(todo => new Date(todo.from) < now)
    .sort((todo_a, todo_b) => todo_a.from - todo_b.from);
};

export const Todos = () => {
  const history = useHistory();
  const { data: todosData } = useQuery(SUGGESTIONS_QUERY, {
    variables: {
      token: localStorage.getItem(TOKEN),
    },
  });
  const { data: topicsData } = useQuery(TOPICS_QUERY, {
    variables: {
      token: localStorage.getItem(TOKEN),
    },
  });

  const { batches = [] } = todosData?.client ?? {};
  const { topics = [] } = topicsData?.me ?? {};
  const availableTodos = filterBatchedTodos(batches);

  return (
    <div>
      {availableTodos.map(item => {
        return (
          <Item key={item._id}>
            <ItemTitle>{item.todo.title}</ItemTitle>
            <ItemButton
              onClick={() => {
                for (const topic of topics) {
                  const { todos = [] } = topic;
                  for (const todo of todos) {
                    const { _id } = todo;
                    if (item.todo._id === _id) {
                      const route = `/topics/${slugging(
                        topic.title
                      )}/${slugging(todo.title)}`;
                      return history.push(route);
                    }
                  }
                }
                return history.push("/topics");
              }}
            >
              View
            </ItemButton>
          </Item>
        );
      })}
    </div>
  );
};
