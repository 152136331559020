import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Backspace from "@material-ui/icons/Backspace";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import logoImage from "../assets/images/ubutler-logo.svg";
import { flowRight } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButtonWithProgress from "./IconButtonWithProgress";
import { translate } from "react-i18next";
import PhosphorIcon from "./phosphor-icons/phosphor-icon";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
});

class LoginPage extends Component {
  state = {
    phone: "",
    verificationId: null,
    smsCode: "",
    error: null,
  };

  moveCaretAtEnd(e) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={logoImage}
            alt="uButler"
            style={{ width: "15em", marginBottom: 40 }}
          />
          <div>
            {!this.state.verificationId ? (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!this.isPhoneValid()) {
                    return;
                  }
                  this._startSmsVerification();
                }}
                style={{ display: "flex" }}
              >
                <TextField
                  disabled={this.state.submitting}
                  autoFocus
                  onFocus={this.moveCaretAtEnd}
                  className={classes.button}
                  id="search"
                  label={t("phoneNumber")}
                  type="tel"
                  margin="normal"
                  value={this.state.phone}
                  onChange={e => {
                    let phone = e.target.value;
                    // TODO Make this actually proactively match the phone format (remove text characters)
                    if (!phone.match(/^[0-9]{0,16}$/)) {
                      return;
                    }
                    this.setState({ phone });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+</InputAdornment>
                    ),
                  }}
                />

                <IconButtonWithProgress
                  disabled={!this.isPhoneValid()}
                  busy={this.state.submitting}
                  variant="fab"
                  color="primary"
                  type="submit"
                  icon={
                    <PhosphorIcon 
                      name="paper-plane"
                      style={{ width: "1.5rem", height: "1.5rem" }} 
                    />
                  }
                />
              </form>
            ) : (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!this.isSmsCodeValid()) {
                    return;
                  }
                  this._completeVerification();
                }}
                style={{ display: "flex" }}
              >
                <IconButton
                  className={classes.button}
                  disabled={this.state.submitting}
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      verificationId: null,
                      smsCode: "",
                    });
                  }}
                >
                  <Backspace />
                </IconButton>
                <TextField
                  disabled={this.state.submitting}
                  autoFocus
                  error={this.state.error != null}
                  helperText={this.state.error}
                  className={classes.button}
                  id="search"
                  label={t("smsCode")}
                  type="text"
                  margin="normal"
                  value={this.state.smsCode}
                  onChange={e => {
                    let smsCode = e.target.value;
                    if (!smsCode.match(/^[0-9]{0,6}$/)) {
                      return;
                    }
                    this.setState({
                      smsCode: smsCode.toUpperCase(),
                      error: null,
                    });
                  }}
                />

                <IconButtonWithProgress
                  disabled={!this.isSmsCodeValid()}
                  busy={this.state.submitting}
                  variant="fab"
                  color="primary"
                  type="submit"
                  icon={
                    <PhosphorIcon 
                      name="paper-plane"
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    />
                  }
                />
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    const field = document.getElementsByTagName("input")[0];
    if (field) {
      field.focus();
    }
  }

  isPhoneValid = () => {
    return this.state.phone.match(/^[0-9]{10,16}$/);
  };

  isSmsCodeValid = () => {
    return this.state.smsCode.match(/^[A-Z0-9]{6}$/);
  };

  _startSmsVerification = async () => {
    this.setState({ submitting: true });

    try {
      const { phone } = this.state;
      const result = await this.props.startSmsVerificationMutation({
        variables: {
          phone: `+${phone}`,
          sessionType: "web",
        },
      });
      this.setState({
        verificationId: result.data.startSmsVerification._id,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  };

  _completeVerification = async () => {
    const { t } = this.props;
    this.setState({ submitting: true });

    try {
      const { smsCode, verificationId } = this.state;
      const result = await this.props.completeVerificationMutation({
        variables: {
          verificationId,
          smsCode,
        },
      });

      const answer = result.data.completeVerification;

      if (answer.success) {
        this.props.onLogin(result.data.completeVerification.token);
      } else {
        this.setState({ error: t("invalidCode") });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  };
}

const START_SMS_VERIFICATION_MUTATION = gql`
  mutation($phone: String!, $sessionType: String!) {
    startSmsVerification(phone: $phone, sessionType: $sessionType) {
      _id
    }
  }
`;

const COMPLETE_SMS_VERIFICATION_MUTATION = gql`
  mutation($verificationId: String!, $smsCode: String!) {
    completeVerification(_id: $verificationId, code: $smsCode) {
      success
      token
    }
  }
`;

const LoginPageWithGraphql = flowRight(
  graphql(START_SMS_VERIFICATION_MUTATION, {
    name: "startSmsVerificationMutation",
  }),
  graphql(COMPLETE_SMS_VERIFICATION_MUTATION, {
    name: "completeVerificationMutation",
  })
)(withStyles(styles)(LoginPage));

export default translate("LoginPage")(LoginPageWithGraphql);
