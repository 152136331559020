let config;

// TODO Add dev config
// TODO Mandatory keys in production

if (process.env.REACT_APP_GRAPHQL_HOST) {
  config = {
    graphqlHttpUrl: `https://${process.env.REACT_APP_GRAPHQL_HOST}/graphql`,
    graphqlWsUrl: `wss://${process.env.REACT_APP_GRAPHQL_HOST}/subscriptions`,
    googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    stripePublishableKey: process.env.REACT_APP_STRIPE_KEY,
  };
} else {
  if (window.location.hostname === "member.ubutler.nl") {
    config = {
      graphqlHttpUrl: "/graphql2",
      graphqlWsUrl: "wss://" + window.location.hostname + "/subscriptions",
      googleMapsKey: "AIzaSyBYxlArCWN9yuZi9_tCGbibhYJymFjQqXU",
      stripePublishableKey: "pk_live_VzkyDxMXKc7sgTltJRAcU8dw",
    };
  } else if (window.location.hostname === "test-member.ubutler.nl") {
    config = {
      graphqlHttpUrl: "/graphql2",
      graphqlWsUrl: "wss://" + window.location.hostname + "/subscriptions",
      googleMapsKey: "AIzaSyBYxlArCWN9yuZi9_tCGbibhYJymFjQqXU",
      stripePublishableKey: "pk_test_AJRCFhV1z5KhFNQmlP5kn0CA",
    };
  } else if (process.env.REACT_APP_GRAPHQL_USE_SAME_DOMAIN === "true") {
    // Because websockets don't understand relative paths at al........
    var url = new URL("/subscriptions", window.location.href);
    url.protocol = url.protocol.replace("http", "ws");

    config = {
      graphqlHttpUrl: `/graphql`,
      graphqlWsUrl: url.toString(),
      googleMapsKey: "AIzaSyBYxlArCWN9yuZi9_tCGbibhYJymFjQqXU",
      stripePublishableKey:
        process.env.REACT_APP_STRIPE_KEY || "pk_test_AJRCFhV1z5KhFNQmlP5kn0CA",
    };
  } else {
    const graphqlHost =
      process.env.REACT_APP_LOCAL_GRAPHQL_HOST ||
      window.location.hostname + ":4008";

    config = {
      graphqlHttpUrl: `http://${graphqlHost}/graphql`,
      graphqlWsUrl: `ws://${graphqlHost}/subscriptions`,
      googleMapsKey: "AIzaSyBYxlArCWN9yuZi9_tCGbibhYJymFjQqXU",
      stripePublishableKey:
        process.env.REACT_APP_STRIPE_KEY || "pk_test_AJRCFhV1z5KhFNQmlP5kn0CA",
    };
  }
}

export default config;
