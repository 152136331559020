import React, { Component } from "react";
import { Link } from "react-router-dom";
import dompurify from "dompurify";
import { formatPrice } from "../../helpers";
import ButtonBase from "@material-ui/core/ButtonBase";
import moment from "moment";
import { translate } from "react-i18next";
import styled from "styled-components";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

let SuggestionItem = styled.div`
  text-decoration: none;
  width: 100%;
  color: var(--donkerblauw);
  border-radius: 5px;
  margin: 5px;
  position: relative;
  display: flex;
  color: var(--donkerblauw);
  /* filter: drop-shadow(0 2px 4px rgba(147, 112, 219, 0.4)); */
  /* filter: drop-shadow(0 0px 1px var(--groen)); */

  & .suggestion-details {
    flex-grow: 4;
    width: calc(75% - 5px);
    background: #fff;
    border-radius: 5px 0 0 5px;
    padding: 5px;
  }
  & .suggestion-details .unit-container {
    display: block;
  }
  & .suggestion-actions {
    flex-grow: 1;
    width: calc(25% - 5px);
    background: #fff;
    display: flex;
    font-size: 0.6rem;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
  }
  & h3 {
    margin: 0 8px;
    border-radius: 3px 3px 0 0;
    color: rgba(147, 112, 219, 1);
    font-size: 0.775rem;
    text-align: left;
    font-weight: normal;
  }
  & .unit {
    margin: 5px;
    margin-bottom: 0px;
  }
  & .unit .icon {
    color: rgba(147, 112, 219, 1);
    width: 1.4rem;
  }
  & .button {
    background: white;
    transition: background 0.2s ease-in-out;
    padding: 10px 5px;
    border-radius: 5px;
    color: rgba(147, 112, 219, 1);
  }
  & .button:hover {
    background: rgba(147, 112, 219, 0.3);
    transition: background 0.2s ease-in-out;
  }
  & .rip {
    width: 10px;
    position: relative;
    margin: 5px 0;
    background: #fff;
  }
  & .rip i {
    display: block;
    height: 95%;
    border: 0.5px dashed rgba(147, 112, 219, 0.5);
    width: 1px;
    position: relative;
    left: calc(50% - 1px);
    top: 3px;
  }
  & .rip::before,
  & .rip::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border: 5px solid transparent;
    border-bottom-color: #fff;
    border-right-color: #fff;
    border-radius: 100%;
    pointer-events: none;
  }
  & .rip::before {
    top: -5px;
  }
  & .rip::after {
    bottom: -25px;
    transform: translate(-50%, -50%) rotate(225deg);
  }

  @media only screen and (min-width: 1024px) {
    & h3 {
      font-size: 0.855rem;
    }
    & .suggestion-actions {
      font-size: 0.775rem;
    }
    & .suggestion-details .unit-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media only screen and (min-width: 1440px) {
    & .rip {
      width: 15px;
    }
  }
`;

class Suggestion extends Component {
  render() {
    const {
      suggestion,
      personalMessage,
      credit,
      topicName,
      todoName,
    } = this.props;

    if (suggestion.__typename === "Suggestion") {
      return (
        <SuggestionItem>
          <section className="suggestion-details">
            <h3>{suggestion.title}</h3>
            <div className="unit-container">
              <p className="unit">
                {suggestion.businessPrivate === "private" ? (
                  <React.Fragment>
                    {/* TODO Change to "Optional"? */}
                    <PhosphorIcon name="question" className="icon" />
                    Optional
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <PhosphorIcon name="warning-circle" className="icon" />
                    Required
                  </React.Fragment>
                )}
              </p>
            </div>
          </section>
          <div className="rip">
            <i />
          </div>
          <section className="suggestion-actions">
            <Link to={`topics/${topicName}/${todoName}`}>
              <ButtonBase className="button">
                <span>Learn more</span>
              </ButtonBase>
            </Link>
          </section>
        </SuggestionItem>
      );
    }

    if (suggestion.__typename === "AssignedTimelineItem") {
      const todo = suggestion.item || suggestion.todo;

      return (
        <SuggestionItem>
          <section className="suggestion-details">
            <h3>{todo.title}</h3>
            <div className="unit-container">
              {Date.now() < new Date(suggestion.till) ? (
                <p className="unit">
                  <PhosphorIcon name="calendar-blank" className="icon" />
                  {" Activate before "}
                  {moment(suggestion.till).calendar(null, {
                    sameDay: "[today]",
                    nextDay: "[tomorrow]",
                    nextWeek: "[next] dddd",
                    sameElse: "DD MMMM",
                  })}
                </p>
              ) : null}
              {Boolean(todo.suggestion) && (
                <p className="unit">
                  {suggestion.item.suggestion.isRequired && (
                    <React.Fragment>
                      <PhosphorIcon name="warning-circle" className="icon"/>
                      Required
                    </React.Fragment>
                  )}
                </p>
              )}
            </div>
            {personalMessage && personalMessage !== "<p><br></p>" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(personalMessage),
                }}
              />
            )}
            {credit > 0 && <div>Credit: {" € " + formatPrice(credit)}</div>}
          </section>
          <div className="rip">
            <i />
          </div>
          <section className="suggestion-actions">
            <Link to={`topics/${topicName}/${todoName}`}>
              <ButtonBase className="button">
                <span>Learn more</span>
              </ButtonBase>
            </Link>
          </section>
        </SuggestionItem>
      );
    }

    return <div />;
  }
}
export default translate("DashboardSuggestion")(Suggestion);
