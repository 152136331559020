import React from "react";
import gql from "graphql-tag";
import { flatten, sortBy } from "lodash";
import { TOKEN } from "../../constants";
import { useQuery } from "react-apollo";
import { translate } from "react-i18next";
import CenteredProgress from "../CenteredProgress";

import Suggestion from "./Suggestion";
import { TOPICS_QUERY } from "../../pages/knowledgeBase";
import { slugging } from "../../helpers";
import PhosphorIcon from "../phosphor-icons/phosphor-icon";

export const SUGGESTIONS_QUERY = gql`
  query($token: String!) {
    client(token: $token) {
      _id
      batches {
        from
        till
        items {
          _id
          status
          credit_in_cents
          personalMessage
          times_usable
          from
          till
          item {
            _id
            title
            admin_title
            city
            suggestion {
              isRequired
              arrange_or_specific
              description
              readmore_text
              definitionflow_text
              firstmessage_text
            }
            batch_id # Batch numbers, or none if always available
            priority # Determines the order of the items while in "Active" timeline
          }
          todo {
            _id
            title
          }
        }
      }
      features {
        chat
      }
    }
  }
`;

let Suggestions = ({ t }) => {
  let { data: suggestions, loading: suggestionsLoading } = useQuery(
    SUGGESTIONS_QUERY,
    {
      variables: {
        token: localStorage.getItem(TOKEN),
      },
    }
  );

  let { data: topicsQuery, loading: topicsLoading } = useQuery(TOPICS_QUERY, {
    variables: {
      token: localStorage.getItem(TOKEN),
    },
  });

  if (suggestionsLoading || topicsLoading) {
    return <CenteredProgress />;
  }

  let now = Date.now(); // TODO Subscribe
  let current_batch_items = flatten(
    suggestions.client.batches.map(batch => batch.items)
  )
    .filter(item => item.status === "ACTIVE")
    .filter(item => new Date(item.from) < now);

  const suggestionsToShow = sortBy([...current_batch_items], x => x.till);

  const topics = topicsQuery?.me?.topics ?? [];
  const knowledgebaseSuggestions = [];

  for (const suggestion of suggestionsToShow) {
    const todoId = suggestion?.todo?._id ?? null;
    if (!todoId) break;
    for (const topic of topics) {
      const topicTodos = topic.todos;
      for (const todo of topicTodos) {
        if (todo._id === todoId) {
          knowledgebaseSuggestions.push({
            ...suggestion,
            topicId: topic._id,
            topicName: topic.title,
            todoName: todo.title,
          });
        }
      }
    }
  }

  if (knowledgebaseSuggestions.length === 0) {
    return (
      <div className="placeholder">
        <PhosphorIcon name="list-ul" className="icon"/>
        <h4>{t("emptyTitle")}</h4>
      </div>
    );
  }

  return (
    <div
      style={{
        overflow: "auto",
        height: "100%",
        marginTop: 8,
      }}
    >
      <div
        style={{
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
          display: "flex",
          width: "calc(100% - 20px)",
          flexWrap: "wrap",
        }}
      >
        {knowledgebaseSuggestions.map(suggestion => (
          <Suggestion
            personalMessage={suggestion.personalMessage}
            credit={suggestion.credit_in_cents}
            suggestion={suggestion}
            topicName={slugging(suggestion.topicName)}
            todoName={slugging(suggestion.todoName)}
            key={suggestion._id || suggestion.item._id}
          />
        ))}
      </div>
    </div>
  );
};

export default translate("Suggestions")(Suggestions);
